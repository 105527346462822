import {ref, reactive, computed, onMounted, onBeforeMount} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  ACT_GET_CRSE_DIST_GLOBAL_LIST,
  ACT_INSERT_CRSE_DIST_REGULAR,
  ACT_GET_GLOBAL_APPLY_LIST, ACT_GET_CRSE_REG_APLY_LIST, ACT_GET_CRSE_DRAW_LIST,
} from '@/store/modules/course/course';
import { getListFunc} from '@/assets/js/ui.init';
import {sortCourseItems} from '@/assets/js/modules/learn/learn-regular-sort';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {
  commonDownloadFile,
  getItem,
  getItems,
  isSuccess,
  lengthCheck,
  numberComma,
  timestampToDateFormat
} from '@/assets/js/util';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import navigationUtils from '@/assets/js/navigationUtils';
import {
  ACT_GET_LEARN_HISTORY_LIST
} from '@/store/modules/learn/learn';
import {crseAplyDcds} from '@/assets/js/modules/common/common';
import ApiService from "@/assets/js/api.service";

export const trainGlobalSetup = () => {
  const isMobile = navigationUtils.any();
  const store = useStore();
  const router = useRouter();
  const {showConfirm, showMessage} = useAlert();
  const {showLoading, hideLoading} = useAlert();
  const currentYear = new Date().getFullYear();
  const session = computed(() => store.state.auth.session);
  const dropdownToggle = reactive({
    title: false,
    file: false,
  });
  const isReady = ref(false);
  const statusToggle = ref(false);
  const items = ref([]);
  const nowitems = ref([]);
  const renderItems = ref([]);
  const aplyItems = ref([]);
  const learns = ref([]);
  const dtlItem = reactive({
    lrnerId: 0,
    reqYn: '',
    digiDeptTyDcd: '',
    digiDeptTyNm: '',
    preYn: '',
    basicYn: '',
    advnYn: '',
  });

  const drawStatus = ref([]);
  const basicLrn = ref('-');
  const advanLrn = ref('-');
  const finalLrn = ref('-');
  const nowbgngdt = ref('9999.12.31');
  const nowbasicbgngdt = ref('');
  const nowadvancedbgngdt = ref('');
  const todaynowcnt = ref('0');
  const isReady2 = ref(false);
  const crseDistList = ref([]);

  const currentTs = new Date().getTime();
  const showDists = ref([]);
  const showDistsSize = ref(10);
  const hToggle = ref(false);

  const showStatus = () => {
    statusToggle.value = true;
  };
  const showAttachments = ref(false);

  const checkPmsn = () =>{
    ApiService.get('/v1/app/auth/global').then(res => {
      if(lengthCheck(res)) {
       if(getItem(res) == 0 && session.value.lrnerId !=='1593117'){
         showMessage('연수신청 대상자가 아닙니다.');
         router.push({name:  "Main"}).then();
       }

      }
    });
  }



  showLoading();

  getListFunc(`course/${ACT_GET_CRSE_DIST_GLOBAL_LIST}`,
      {bgngYmd: `${currentYear}-01-01`, endYmd: `${(currentYear + 1)}-01-01`}, items, null, () => {
        renderItems.value = items.value.map(x => (
            {
              ...x,
              title: '글로벌탐험대',
              items: sortCourseItems(x.splCrseTyCdDcd, items.value.filter(y => x.splCrseTyCdDcd === y.splCrseTyCdDcd)),
            }
        ));
        hideLoading();
        isReady.value = true;
      });

  getListFunc(`course/${ACT_GET_GLOBAL_APPLY_LIST}`, {}, aplyItems, null, () => {});

  getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null, () => {
    if (learns.value.filter(x => x.crseMstSn === 100331 && x.fnshYn === 'Y').length > 0) {
      basicLrn.value = 'Y';
    }
    if (learns.value.filter(x => x.crseMstSn === 100331 && x.fnshYn === 'N').length > 0) {
      basicLrn.value = 'N';
    }
    if (learns.value.filter(x => x.crseMstSn === 100332 && x.fnshYn === 'Y').length > 0) {
      advanLrn.value = 'Y';
    }
    if (learns.value.filter(x => x.crseMstSn === 100332 && x.fnshYn === 'N').length > 0) {
      advanLrn.value = 'N';
    }
    if (learns.value.filter(x => x.crseMstSn === 100334 && x.fnshYn === 'Y').length > 0) {
      finalLrn.value = 'Y';
    }
    if (learns.value.filter(x => x.crseMstSn === 100334 && x.fnshYn === 'N').length > 0) {
      finalLrn.value = 'N';
    }
  });


  const isLoading = ref(false);
  const enrollCourse = (item) => {
    if (isLoading.value) return;
    isLoading.value = true;

    store.dispatch(`course/${ACT_INSERT_CRSE_DIST_REGULAR}`, item.distCrseSn).then(res => {
      if (isSuccess(res)) {
        router.push({name: 'LearnRegularMain', params: {distCrseSn: item.distCrseSn}});
      } else {
        showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        isLoading.value = false;
      }
    }).catch(e => {
      console.error(e);
      showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      isLoading.value = false;
    });
  };

  const goLearn = (item) => {
    if (item.lrnTrgtGrpDtlSn > 0) {
      router.push({name: 'LearnRegularMain', params: {distCrseSn: item.distCrseSn}});
    } else {
      showConfirm({
        title: item.crseNm,
        text: '해당 연수에 입과하시겠습니까?',
        callback: () => {
          enrollCourse(item);
        },
      });
    }
  };
  const edit = reactive({
    toggle: false,
    target: null,
  });
  const getFinishText = (item) => {
    // 입과 하였을 경우
    if (item.lrnTrgtGrpDtlSn > 0 && item.learns.length > 0) {
      // 콜드콜을 사용하는 경우...
      return item.fnshYn === 'Y' ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
    }
    return '<span class="text-muted">미완료</span>';
  };

  const getDigitalFinishText = (target, item) => {
    if (target === 'trgt') {
      return item === 'Y' ?
          '<span class="text-primary">대상</span>' :
          '<span class="text-muted">미대상</span>';
    } else {
      return item === 'Y' ?
          '<span class="text-primary">완료</span>' :
          '<span class="text-muted">미완료</span>';
    }
  };
  const getDivision = (item) => {
    let division = true;
    let msg = '';

    if (item.takeSttDcd) {
      // 입과 완료
      if (item.lrnTrgtGrpDtlSn > 0) {
        if (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY ||
            item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE ) {
          // console.log(item);
          if (item.absdAplyYn === 'N' && item.seqChgYn === 'N') {
            division = false;
            msg = '해당연수는 불참(취소)/회차변경이 불가합니다.';
          }
          else {
            if ((currentTs < item.absdAplyBgngDt || currentTs > item.absdAplyEndDt) &&
                (currentTs < item.seqChgBgngDt || currentTs > item.seqChgEndDt) )
            {
              division = false;
              msg = '불참(취소)/회차변경 기간이 아닙니다.';
            }
          }
        }
      }
      // 입과 대기
      else {
        // 취소일 경우
        if (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY) {
          if (item.rtrcnPsbltYn === 'Y') {
            if (currentTs >= item.rtrcnBgngDt && currentTs < item.rtrcnEndDt) {
              // cancel
              division = true;
            } else {
              division = false;
              msg = '신청취소 기간이 아닙니다.';
            }
          } else {
            division = false;
            msg = '해당연수는 신청취소가 허용되지 않습니다.';
          }
        } else {
          division = false;
          msg = '신청취소 기간이 아닙니다.';
        }
      }
    }

    if (!division) {
      showMessage(msg);
    }

    return division;
  };
  const showEditModal = (dist) => {
      // console.log(getDivision(dist))
    if (getDivision(dist)) {
      if (isMobile) {
        router.push({name: 'ApplyStatusEdit', params: {distCrseSn: dist.distCrseSn}})
      } else {
        edit.target = dist;
        edit.toggle = true;
      }
    }
  };
  const limitOver = (dist) => {
    return (dist.peoplCnt > 0 && dist.peoplCnt <= dist.aplyRegCnt);
  };

  const excludeDist = [202333, 202334];
  const isShowApply = (dist) => {
    // 국민은행 영업점 직원은 1, 2 회차 숨김
    if (dtlItem.digiDeptTyDcd === '2112001' && session.value.susdyCdDcd === '2109001') {
      if (excludeDist.includes(dist.distCrseSn)) {
        return false;
      } else {
        // return (!limitOver(dist.peoplCnt, dist.aplyRegCnt) && dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
        return (dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
      }
    } else {
      // return (!limitOver(dist.peoplCnt, dist.aplyRegCnt) && dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
      return (dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
    }
  };
  const getCourseRegList = () => {
    //페이지 새로고침 필요
    location.reload();
  };
  const showApplyViewMobile = (key) => {
    router.push({name: 'ApplyTrainView', params: {distCrseSn: key}});
  };

  const initShow = () => {
    // console.log(props.pageNo);
    showDists.value = [];
    for (let i = 0; i < showDistsSize.value; i++) {
      showDists.value[i] = false;
    }
  }

  const collapseOrExpand = (index) => {
    // console.log(index, showDists.value[index]);
    showDists.value[index] = !showDists.value[index];
    return showDists.value[index];
  }

  initShow();





  const headerToggle = () => {
    if (hToggle.value) {
      hToggle.value = false;
    } else {
      hToggle.value = true;
    }
  };

  const showApplyView = (index) => {
    // console.log(index);
    router.push({name: 'ApplyTrainView', params: {distCrseSn: index}});
  }

  const downloadAtch = (atch) => {
    commonDownloadFile(atch);
  };



  const clickBack = () => {
    router.push({path: '/main'});
  };

  const getDrawStatus= (splCrseTyCdDcd)=>{
    store.dispatch(`course/${ACT_GET_CRSE_DRAW_LIST}`, {splCrseTyCdDcd: splCrseTyCdDcd}).then((res) => {
      if(lengthCheck(res)){
        getItems(res).filter(data => {
          // 글로벌탐험대 만 보여줌

          drawStatus.value.push(data);
          console.log(data);

        });
      }else{
        drawStatus.value = [];
      }
    }).finally(() => {
      isLoading.value = false;
    });
  }

  onBeforeMount(()=>{
    checkPmsn();
  });
  onMounted(() => {
    // 입과 차수 정보 불러오기
    store.dispatch(`course/${ACT_GET_CRSE_REG_APLY_LIST}`, {}).then((res) => {
      if(lengthCheck(res)){
        getItems(res).filter(data => {
          // 글로벌탐험대 만 보여줌
          if(data.splCrseTyCdDcd === '2066030' && data.regCrseAplySttDcd ==='2049001'){
            crseDistList.value.push(data);
            console.log(crseDistList)

          }


          // console.log(data);
        });
        getDrawStatus('2066030');
        // console.log(crseDistList);
      }else{
        crseDistList.value = [];
      }
      // console.log(crseDistList.value);
    }).finally(() => {
      isLoading.value = false;
    });



  })

  return {
    isReady,
    isReady2,
    statusToggle,
    items,
    nowitems,
    renderItems,
    showStatus,
    aplyItems,
    dtlItem,
    learns,
    basicLrn,
    advanLrn,
    finalLrn,
    session,
    nowbgngdt,
    nowbasicbgngdt,
    nowadvancedbgngdt,
    todaynowcnt,

    goLearn,
    timestampToDateFormat,
    numberComma,
    getThumbUrl,
    getFinishText,
    getDigitalFinishText,
    limitOver,
    isMobile,
    isShowApply,
    showApplyViewMobile,
    collapseOrExpand,
    showDists,
    hToggle,
    headerToggle,
    showAttachments,
    downloadAtch,
    showApplyView,
    crseDistList,
    crseAplyDcds,
    showEditModal,
    edit,
    getCourseRegList,
    clickBack,
    dropdownToggle,
    drawStatus,
  };
}
