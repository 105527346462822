<template>
  <LxpMobileHeader :use-route-name="true">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="isMobile" class="header-util util-actions-back">
        <div class="kb-form-dropdown left file" :class="{'is-active': dropdownToggle.file}">
          <button class="kb-form-dropdown-btn" @click.stop="dropdownToggle.file = !dropdownToggle.file">
            <span class="text">첨부파일<i class="icon-download" /></span>
          </button>
          <div class="kb-form-dropdown-options" style="left:-140px; right:0px;">
            <div class="dropdown-option">
              <ul class="dropdown-option-list" @click.stop="dropdownToggle.file = !dropdownToggle.file">
                <li class="dropdown-option-item">
                  <FileDownLink file-key="TrainGlobalGuide" :add-link-class="['dropdown-option-link']" :add-text-class="['dropdown-option-text']" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

<!--        <div class="util util-download">-->
<!--          <a href="javascript:" @click="showAttachments= true" class="util-actions util-actions-download">-->
<!--            첨부파일-->
<!--            <i class="icon-download"></i>-->
<!--          </a>-->
<!--        </div>-->
    </template>

  </LxpMobileHeader>
  <main class="kb-main" id="kb-educourse">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="educourse-view-container mb-0">
        <div class="view-contents">
          <!-- content-section:과정안내 -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청현황</h4>
            </header>
            <template v-for="(item, idx) in crseDistList" :key="idx">
              <div class="text-item-container">
                <div class="content-item">

                  <div class="title mb-2">신청회차 &nbsp;
                    <template v-if="(crseDistList.length>0 && (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||
                                  item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&
                                  item.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT)">
                      <strong class="td-text" v-html="item.crseNm"></strong><br>
                      <strong class="td-text">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~
                        {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</strong>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>

<!--                  <div class="title mb-2">부점장승인여부 &nbsp;&nbsp;-->
<!--                    <template v-if="(crseDistList.length>0 && (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||-->
<!--                                  item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&-->
<!--                                  item.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT)">-->
<!--                      <strong v-if=" item.atrzSttNm01" class="td-text" v-html="item.atrzSttNm01"></strong>-->
<!--                      <strong v-else >미승인</strong>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      <strong>-</strong>-->
<!--                    </template>-->
<!--                  </div>-->
                  <div class="title mb-2">
                    <template v-if="(crseDistList.length>0 && (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||
                                  item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&
                                  item.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT)">
                      <button v-if="crseDistList.length>0 &&  item.takeSttDcd !== '2050002' && item.aplyEndDt >= new Date().getTime()"
                              class="can-button" type="button" @click="showEditModal( item)">
                        <span class="text">불참(취소)</span>
                      </button>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div class="title mb-2">선정여부 &nbsp;&nbsp;

                    <template v-for="(drawItem, idx) in drawStatus" :key="idx">
                      <template v-if="item.distCrseSn === drawItem.distCrseSn ">
                        <template v-if = "drawItem.winStatusYn === 'Y'">
                          <strong class="td-text text-success" >선정</strong>
                        </template>
                        <template v-else>
                          <strong class="td-text text-danger" >미선정</strong>
                        </template>
                      </template>
                    </template>

                  </div>
                  <div class="title mb-2">대기여부 &nbsp;&nbsp;
                    <template v-for="(drawItem, idx) in drawStatus" :key="idx">
                      <template v-if="item.distCrseSn === drawItem.distCrseSn ">
                        <template v-if = "drawItem.waitStatusYn === 'Y'">
                          <strong class="td-text text-primary" >대기( 순번: {{drawItem.waitRankSn}} )</strong>
                        </template>
                        <template v-else>
                          <strong class="td-text" >-</strong>
                        </template>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <div class="d-flex justify-content-center mb-2">
              <p class="text-muted" style="font-size: 12px;">※ 대상자 선정 완료 후 별도 확인 안내 예정입니다.</p>
            </div>
          </section>
        </div>
      </div>
      <!-- content-section:진행중 연수 -->



      <template v-if="aplyItems.length > 0">
        <article class="content-section" style="padding: 30px 10px 0px 16px">
          <header class="section-header">
            <h4 class="title">신청과정목록<span v-if="aplyItems.length > 0" class="count">{{ aplyItems.length }}</span></h4>
          </header>
        </article>
        <div class="educourse-list-container">
          <ul class="educourse-list">
            <li v-for="(item, index) in aplyItems" :key="index" class="list-item">
              <div class="educourse">
                <div class="educourse-summary course-row" style="padding-bottom: 10px;cursor: pointer"
                     @click="collapseOrExpand(index)">
                  <article class="course-card">
                    <div class="course-image">
                      <figure class="image-figure">
                        <img v-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                        <CourseThumb
                            v-else
                            :num="item.crseMstSn"
                        />
                      </figure>
                    </div>
                    <div class="course-content">
                      <div class="content-header">
                        <h5 class="title">
                          {{ item.crseMstNm }}
                        </h5>
                      </div>
                      <div :class="{'content-footer': isMobile}">
                        <div class="content-meta">
                          <span class="text" :class="{'txt_line' : !isMobile, 'txt_multi' : isMobile}" style="color:var(--kb-gold)">{{ item.crseSumup }}</span>
                        </div>
                        <div class="content-meta" style="margin-top: 20px">
                          <span class="text">
                            {{ item.orgnzNm }}
                            {{ item.mstTotLrnPerid ? ' | '.concat(item.mstTotLrnPerid,'일') : '' }}
                            {{ item.crseMstMlg ? ' | 마일리지'.concat(item.crseMstMlg) : '' }}
                          </span>
                        </div>
                        <div v-if="isMobile" class="striped-list-wrap">
                          <ul class="striped-list">
                            <li class="striped-item">
                              <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                                <div class="kb-collapse-toggle striped-row" style="background-color: white">
                                  <div class="striped-column column-arrow" style="padding: 0px 0px;min-height: 0px"><i class="icon-arrow"></i></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>

                  <template v-if="showDists[index]">
                    <template v-if="item.crseDists.length > 0">
                      <template v-for="(dist, idx) in item.crseDists" :key="idx">
                        <template v-if="isShowApply(dist)">
                          <div class="educourse-content" style="border: 0px">
                            <div class="educourse-summary">
                              <p class="title">{{ dist.crseNm }}</p>
                              <p class="status text-red">
                                <span>
                                  신청인원수 {{dist.aplyRegCnt > 0 ?
                                    `${numberComma(dist.aplyRegCnt)}명` :
                                    '-'}}
                                </span>
                                <span v-if="limitOver(dist)"> ・ 정원초과</span>
                              </p>
                            </div>
                            <div class="educourse-date">
                              <div class="date-item">
                                <div class="title">연수기간</div>
                                <div>
                                  {{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd') }} -
                                  {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd') }}
                                </div>
                              </div>
                              <div class="date-item">
                                <div class="title">신청기간</div>
                                <div>
                                  {{ timestampToDateFormat(dist.aplyBgngDt, 'yyyy.MM.dd') }} -
                                  {{ timestampToDateFormat(dist.aplyEndDt, 'yyyy.MM.dd hh:mm') }}
                                </div>
                              </div>
                            </div>
                            <div class="educourse-actions">
                              <button class="kb-btn-apply" @click="showApplyViewMobile(dist.distCrseSn)">연수신청</button>
                            </div>
                          </div>
                        </template>
                      </template>
                    </template>
                  </template>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <!-- //main-content -->
  </main>
<!--  <MobileTrainDigitalFileAtch v-if="showAttachments" v-model:showAttachments="showAttachments" :fileItems="files"/>-->
</template>

<script>

import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {trainGlobalSetup} from '@/assets/js/modules/train/train-global-setup';
import CourseThumb from '@/components/apply/CourseThumb.vue';
// import MobileTrainDigitalFileAtch from "@/views/pages/train/mobile/MobileTrainDigitalFileAtch";
// import FileDownLink from "@/components/common/FileDownLink";
import FileDownLink from '@/components/common/FileDownLink';

export default {
  name: 'MobileTrainDigital',
  components: {LxpMobileHeader, CourseThumb
    , FileDownLink
  },
  setup: trainGlobalSetup
}
</script>

<style scoped>


</style>